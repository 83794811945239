import React from 'react'
import { graphql } from 'gatsby'
import { filter } from 'lodash/fp'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import Layout from '../components/layout'
import googleMapsStyles from '../../static/styles/google-maps-styles.json'
import marker from '../../static/images/pinu.png'

const MyMapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={13}
      defaultCenter={{ lat: props.position.lat, lng: props.position.lon }}
      defaultOptions={{
        scrollWheel: false,
        styles: googleMapsStyles,
      }}
    >
      {<Marker position={{ lat: props.position.lat, lng: props.position.lon }} icon={marker} />}
    </GoogleMap>
  ))
)

export default class ContactPage extends React.Component {
  render() {
    const { data, location } = this.props

    const { allOfficesJson } = data
    const {
      node: { offices },
    } = allOfficesJson.edges[0]
    const unregisteredOffices = filter(o => o.registered === false)(offices)

    return (
      <Layout type={'home'} location={location} title="Contact" description="Contact us">
        <div className="">
          {unregisteredOffices.map((office, i) => {
            const borderTop = i !== 0 ? 'pt4 pt5-ns bt b--light-gray' : ''
            return (
              <div className={`mv5-ns mv4`} key={i}>
                <div className="mw7-m mw8-l center ph3 ph3-ns pv0-ns">
                  <div className={`${borderTop} cf mh-2 mh-3-l`}>
                    <div className="fl w-100 w-third-m w-third-l ph2 ph3-l">
                      <p className="ma0 mb4 ttu tracked f5 mono">{office.location}</p>
                    </div>
                    <div className="fl w-100 w-two-thirds-m w-two-thirds-l ph2 ph3-l">
                      <MyMapComponent
                        isMarkerShown
                        googleMapURL={
                          'https://maps.googleapis.com/maps/api/js?key=AIzaSyCfqYtqLkAqD-EIKj0_Cz3CXLoDTtB_ZyU&v=3.exp&libraries=geometry,drawing,places'
                        }
                        loadingElement={<div className={'w-100 h5 mb3'} />}
                        containerElement={<div style={{ height: `300px` }} />}
                        mapElement={<div className="h5" style={{ marginBottom: '10px' }} />}
                        position={office.geolocation}
                      />
                      <p className="mv3">{office.name}</p>
                      <div className="cf mh-3-l">
                        <div className="fl w-100 w-50-l ph3-l">
                          <p className="mv0">
                            <span /> {office.phone}
                          </p>
                          <p className="mt0 mb2 mb0-l">
                            <span>E :</span>{' '}
                            <a
                              className="link near-black link-underlined-left"
                              href={`mailto:${office.mail}`}
                            >
                              {office.mail}
                            </a>
                          </p>
                        </div>
                        <div className="fl w-100 w-50-l ph3-l">
                          <div>
                            <p className="mv0">{office.address.street}</p>
                            <p className="mv0">{office.address.city}</p>
                            <p className="mv0 mt3-l">
                              <a
                                className="link near-black no-underline link-underlined-left"
                                href={office.address.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Open Maps ↗
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    allOfficesJson {
      edges {
        node {
          offices {
            location
            registered
            address {
              street
              city
              url
            }
            geolocation {
              lat
              lon
            }
            mail
            phone
          }
        }
      }
    }
  }
`
